import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import CardMedia from '@material-ui/core/CardMedia';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  toolbarMain: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
  },
  mainFeaturedPost: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
  mainFeaturedPostContent: {
    padding: `${theme.spacing.unit * 6}px`,
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    },
  },
  mainGrid: {
    marginTop: theme.spacing.unit,
  },
  markdown: {
    padding: `${theme.spacing.unit * 3}px 0`,
  },
  sidebarAboutBox: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing.unit,
  },
  sidebarSection: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing.unit * 3,
  },
  workExperienceSection: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing.unit * 2,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing.unit * 2,
    padding: `${theme.spacing.unit * 2}px 0`,
  },
  chip: {
    margin: theme.spacing.unit / 3,
  },
  li: {
      margin: '7px 0'
  },
  headerCard: {
    marginLeft: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    width: 150
  },
  headerCardMedia: {
      width: 150
  },
  projectCard: {
    width: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 3
  },
  icon: {
    margin: theme.spacing.unit / 3,
  },
  centerDiv: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  },
});

const mrwishfishTechnologies = [
    { key: 0, label: 'React'},
    { key: 1, label: 'Node.js'},
    { key: 2, label: 'Express.js'},
    { key: 3, label: 'Material-UI'},
    { key: 4, label: 'ES6'},
    { key: 5, label: 'AWS'},
];

const workExperience = [
    {
        company: 'Veratics, Inc.',
        location: 'Indian Harbour Beach, FL',
        jobTitle: 'Senior Software Engineer/Product Owner',
        yearsActive: '12/2014 - 12/2018',
        contributions: [
            {
                key: 0,
                bullet: 'Developed key functionality for an e-commerce marketplace home to 500,000+ products using C# .NET, AngularJS, Elasticsearch, and MS SQL Server. Implemented a PCI-compliant vault for securely encrypting, storing, and decrypting payment information. Implemented a document ingest pipeline to make documents searchable within Elasticsearch.'
            },
            {
                key: 1,
                bullet: 'Served as technical lead on a security vulnerability scanning and remediation effort for the VA’s Office of Construction and Facilities Management budgeting system resulting in the remediation of roughly 200 critical- and high-level vulnerabilities.',
            },
            {
                key: 2,
                bullet: 'Implemented clinical features and enhancements for the VA’s web-based Enterprise Health Management Platform by developing backend RESTful JSON API resources using Node.js and Express.js and integrating API calls on the front end using Backbone.js and Marionette.js.',
            },
            {
                key: 3,
                bullet: 'Managed the product backlog for a web-based personal health tracking telemetry tool by transforming customer requirements into a technical product backlog for the team.',
            },
            {
                key: 4,
                bullet: 'Aided the company in achieving its CMMI Maturity Level 2 certification by championing and executing new processes and serving on the CMMI assessment team.',
            },
            {
                key: 5,
                bullet: 'Successfully navigated the startup culture by wearing multiple hats, managing simultaneous projects, and defining/establishing/maintaining processes that scaled with the company’s growth.',
            }

        ]
    },
    {
        company: 'Harris Corporation - Healthcare Solutions',
        location: 'Melbourne, FL',
        jobTitle: 'Software Engineer II/ScrumMaster',
        yearsActive: '1/2013 - 12/2014',
        contributions: [
            {
                key: 6,
                bullet: 'Contributed to the development of the FusionFx Provider and Patient Portal web-based applications by architecting and implementing solutions interfacing with Java, ServiceMix, GXT, GWT, Camel, Karaf, OSGi, JPA/JDBC, and JMS.',
            },
            {
                key: 7,
                bullet: 'Led the design evaluation and implementation of a ServiceMix component used to aggregate information about system entities (users, patients, providers, services, etc.) from across the entire system.',
            },
            {
                key: 8,
                bullet: 'Designed and implemented a ServiceMix component to store clinical report documents from various sources into multiple data stores.',
            },
            {
                key: 9,
                bullet: 'Created a custom service wrapper for ServiceMix to eliminate manual configuration and decrease installation time when installing the backend as a service on Windows and Linux.',
            },
            {
                key: 10,
                bullet: 'Completed feature requests for the GWT/GXT front-end of the application including custom UI validators and widgets.',
            }
        ]
    },
    {
        company: 'Peake Healthcare Innovations',
        location: 'Melbourne, FL',
        jobTitle: 'Software Engineer II/ScrumMaster',
        yearsActive: '1/2011 - 1/2013',
        contributions: [
            {
                key: 11,
                bullet: 'Utilized Scrum methodologies to implement a medical imaging management system within a highly secure, cloud-based infrastructure for Johns Hopkins Medicine.',
            },
            {
                key: 12,
                bullet: 'Developed a combination of Windows-based, Linux-based, and web-based data-driven applications, which included integrating custom code with commercial off-the-shelf and open source products.',
            },
            {
                key: 13,
                bullet: 'Architected and implemented solutions to integrate software components that communicate with various electronic health information protocols and standards like HL7 and DICOM to manage patient imaging information.',
            }
        ]
    },
    {
        company: 'Institute of Nuclear Power Operations',
        location: 'Atlanta, GA',
        jobTitle: 'Co-op/Programmer Analyst',
        yearsActive: '1/2007 - 1/2010',
        contributions: [
            {
                key: 14,
                bullet: 'Enhanced, maintained, and developed Windows-based and web-based data-driven applications used internally within the company and used externally by the nuclear power industry using C# .NET, ASP .NET, VB .NET, ASP, SSRS, SQL Server.',
            },
            {
                key: 15,
                bullet: 'Fulfilled programming, design, and analysis tasks including the development of new software, maintenance of existing applications and troubleshooting problems with data and third-party software.',
            },
            {
                key: 16,
                bullet: 'Developed applications with the effective use of requirements gathering, project management, and application development in accordance with industry standards.',
            }
        ]
    },
];

function Portfolio(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.layout}>
        <main>
          {/* Main featured post */}
          <Paper className={classes.mainFeaturedPost}>
            <Grid container>
                <Grid item md={2}>
                <Card className={classes.headerCard}>
                    <CardMedia
                        component="img"
                        alt="Amanda Prospal"
                        className={classes.headerCardMedia}
                        image='/static/images/amandaprospal.JPG'
                        title="Amanda Prospal"
                        />
                </Card>
              </Grid>
              <Grid item md={10}>
                <div className={classes.mainFeaturedPostContent}>
                  <Typography component="h1" variant="h3" color="inherit" >
                    Amanda Prospal
                  </Typography>
                  <Typography variant="h5" color="inherit" paragraph>
                    Software Engineer
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Paper>
          {/* End main featured post */}
          <Grid container spacing={40} className={classes.mainGrid}>
            {/* Main content */}
            <Grid item xs={12} md={8}>
            <Paper elevation={0} className={classes.workExperienceSection}>
              <Typography variant="h5" gutterBottom>
                  Latest Project
              </Typography>
              
              <Card className={classes.projectCard}>
                  <CardMedia
                        component="img"
                        alt="Mr. Wish Fish"
                        image="/static/images/mrwishfish.png"
                        title="Mr. Wish Fish"
                  />
                  <CardContent>
                  <Typography component="h2" variant="h5">
                        Mr. Wish Fish
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                      Work in Progress
                      </Typography>
                      <Typography variant="subtitle1" paragraph>
                      Mr. Wish Fish is an online wishlist management tool that enables you to easily manage and curate wishlists to share with your family and friends.
                      </Typography>

                    <div className={classes.centerDiv}>
                    {mrwishfishTechnologies.map(technology => (
                        <Chip key={technology.key} label={technology.label} className={classes.chip}/>
                     ))
                    }
                    </div>
                    <div className={classes.centerDiv}>
                        <Button variant="outlined" target="_blank" href='https://github.com/amandaprospal/mrwishfish' className={classes.button}>
                            View it on GitHub
                        </Button>
                     </div>
                  </CardContent>
                </Card>
              

            </Paper>
              <Paper elevation={0} className={classes.workExperienceSection}>
                <Typography variant="h5" gutterBottom>
                    Work Experience
                </Typography>

                {
                    workExperience.map(
                        (experience, index) => (
                            <div key={index}>
                            <Typography variant="h6">
                                {experience.company}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {experience.jobTitle}
                            </Typography>
                            <Typography>
                                {experience.yearsActive}
                            </Typography>
                                <ul>
                                {
                                    experience.contributions.map(
                                        (contribution, index) => (
                                            <div key={index}>
                                                <Typography>
                                                    <li key={index} className={classes.li}>{contribution.bullet}</li>
                                                </Typography>
                                            </div>
                                        )
                                    )
                                }
                                </ul>
                            </div>
                        )
                    )
                }
              </Paper>
            </Grid>
            {/* End main content */}
            {/* Sidebar */}
            <Grid item xs={12} md={4}>
              <Paper elevation={0} className={classes.sidebarSection}>
                <Typography variant="h5" gutterBottom>
                  About
                </Typography>
                <Typography gutterBottom>
                    I love building software. Period. To me, software development is the perfect blend of creativity and problem solving.
                </Typography>
                <Typography gutterBottom>
                    I fell in love with coding at 10 years old when my dad suggested my sister and I "go make a website" when we were looking for something to do.
                </Typography>
                <Typography gutterBottom>
                    I immediately became obsessed with the art of designing and developing websites, and it was my favorite hobby growing up. I decided at the age of 13 that I wanted to be a programmer, and I haven't looked back.
                </Typography>
                <div className={classes.centerDiv}>
                    <IconButton target="_blank" href="https://www.linkedin.com/in/amandaprospal/" color="inherit">
                        <Icon className={classNames(classes.icon, 'fa fa-linkedin-square')}/>
                    </IconButton>
                    <IconButton target="_blank" href="https://github.com/amandaprospal" color="inherit">
                        <Icon className={classNames(classes.icon, 'fa fa-github-square')} />
                    </IconButton>
                    <IconButton target="_blank" href="https://www.instagram.com/amandaprospal/" color="inherit">
                        <Icon className={classNames(classes.icon, 'fa fa-instagram')} />
                    </IconButton>
                    <IconButton target="_blank" href="mailto:amanda.prospal@gmail.com" color="inherit">
                     <Icon className={classNames(classes.icon, 'fa fa-envelope-o')} />
                    </IconButton>
                </div>
              </Paper>

              <Paper elevation={0} className={classes.sidebarSection}>
                <Typography variant="h5" gutterBottom>
                  Skills
                </Typography>
                <Typography>
                  I have 9+ years of professional work experience. Here are a few of the languages and frameworks I am most proficient in:
                </Typography>
                <ul>
                    <li>
                        <Typography>
                        JavaScript
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        Node.js
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        Express.js
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        React
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        AngularJS
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        Java
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                        C#
                        </Typography>
                    </li>
                </ul>
              </Paper>

              <Paper elevation={0} className={classes.sidebarSection}>
                <Typography variant="h5" gutterBottom>
                  Education
                </Typography>
                <Typography>
                Bachelor of Science, Computer Science
                </Typography>
                <Typography color="textSecondary">
                  Georgia Institute of Technology (2010)
                </Typography>
              </Paper>

              <Paper elevation={0} className={classes.sidebarSection}>
                <Typography variant="h5" gutterBottom>
                  Hobbies
                </Typography>
                <Typography gutterBottom>
                  When I'm not working, I love filling my free time with family, friends, food, and the following activities:
                </Typography>
                <Typography>
                    <Icon className={classNames(classes.icon, 'fa fa-book')}/> Reading books
                    {/*<i class="fa fa-book" aria-hidden="true"></i>Reading books*/}
                </Typography>
                <Typography>
                    <Icon className={classNames(classes.icon, 'fa fa-gamepad')}/> Playing video games
                    {/*<i class="fa fa-gamepad" aria-hidden="true"></i>Playing video games*/}
                </Typography>
                <Typography>
                    <Icon className={classNames(classes.icon, 'fa fa-pencil')}/> Writing fiction
                    {/*<i class="fa fa-pencil" aria-hidden="true"></i>Writing fiction*/}
                </Typography>
                <Typography>
                    <Icon className={classNames(classes.icon, 'fa fa-laptop')}/> Developing software
                    {/*<i class="fa fa-laptop" aria-hidden="true"></i>Developing software*/}
                </Typography>
              </Paper>

            </Grid>
            {/* End sidebar */}
          </Grid>
        </main>
      </div>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography align="center" gutterBottom>
          "Life is a gift, and it offers us the privilege, opportunity, and responsibility to give something back by becoming more."
        </Typography>
        <Typography align="center" color="textSecondary" component="p">
          Tony Robbins
        </Typography>
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}

Portfolio.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Portfolio);