import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.css';
import Portfolio from './Portfolio';

class App extends Component {
  render() {
    return (
        <Portfolio />
    );
  }
}

export default withRouter(App);
